
import { Browser } from '@capacitor/browser';
import {  IonPage, IonContent, loadingController, alertController, IonHeader, IonTitle, IonToolbar, IonBackButton } from '@ionic/vue';
import { defineComponent, reactive, onMounted } from 'vue';
import { useStore } from "vuex";
import { Analytics } from '../../common/analytics';
import { VERSION } from '../../common/config';
import { getPlatforms } from '@ionic/vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: { IonPage, IonContent, IonHeader, IonTitle, IonToolbar, IonBackButton },
   setup() {
      const store = useStore();
      const router = useRouter();
      const screenName = "PAGE-SETTING";
      const analytics = new Analytics;

      const state = reactive({
        version: {
          number: 0,
          code: ''
        }
      })

      const platforms = getPlatforms();
      if ( platforms.includes('hybrid') && platforms.includes('ios') ) {
        state.version.number = VERSION.ios.number;
        state.version.code = VERSION.ios.code;
      } else if ( platforms.includes('hybrid') && platforms.includes('android') ) {
        state.version.number = VERSION.android.number;
        state.version.code = VERSION.android.code;
      } else {
        state.version.number = VERSION.web.number;
        state.version.code = VERSION.web.code;

      }

      const presentAlert = async (message: string) => {
        const alert = await alertController
          .create({
            header: "알림",
            message: message,
            buttons: ['확인'],
          });
        await alert.present();

        const { role } = await alert.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
      };

      const presentConfirm = async (message: string, callback: any = null) => {
        const alert = await alertController
          .create({
            header: "알림",
            backdropDismiss: false,
            message: message,
            buttons: [
              {
                text: '확인',
                handler: () => {
                  if ( callback ) {
                    callback();
                  }
                },
              },
              {
                text: '취소'
              },
            ],
          });
        await alert.present();
        const { role } = await alert.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
      };

      const onLogout  = async () => {
        presentConfirm('로그아웃 하시겠습니까?',async () =>{
          const loading = await loadingController
            .create({
              cssClass: 'my-custom-class',
              message: '로그아웃 중입니다.',
          });
          await loading.present();

          store
            .dispatch("logout")
            .then(response => {
              console.log(response);
              if (response.status == 204) {
                setTimeout(function() {
                  loading.dismiss()
                  window.location.reload();
                }, 1000);
              } else {
                setTimeout(function() {
                  loading.dismiss()
                }, 1000);
              }
            })
            .catch(error => {
              let message = '';
              try {
                message = error.response.data.message;
              } catch (e) {
                message = '';
                console.log(e);
              }
              console.log(message);
              setTimeout(function() {
                loading.dismiss()
                presentAlert(message);
              }, 1000);
            });
        });
      }

      const onOpenUrl = async(url: string)=> {
        if ( url.length ) {
          await Browser.open({ url: url });
        }
      }

      const BlockLink = () => {
        router.push({
          name: "SettingBlock"
        })
      }

      const ReportLink = () => {
        store.commit("report", {
          tpye: '',
        });
        router.push({
          name: "SettingReport"
        })
      }

      onMounted(() => {
        analytics.setScreenName(screenName);
      })

      return { state, onLogout, onOpenUrl,ReportLink, BlockLink }
    }
})
